<template>
  <ToolBar @added-node="addedNode" />
  <OtherNodes />
  <MyNodes :added="added" />
</template>

<script>
// @ is an alias to /src
import ToolBar from '@/components/ToolBar.vue'
import MyNodes from '@/components/MyNodes.vue'
import OtherNodes from '@/components/OtherNodes.vue'

export default {
  mounted() {
    this.$store.dispatch('getMicrocosm')
  },
  name: 'Nodes',

  components: {
    ToolBar,
    MyNodes,
    OtherNodes,
  },

  data() {
    return {
      added: false,
    }
  },

  methods: {
    addedNode() {
      this.added = !this.added
    },
  },
}
</script>

<style scoped></style>
