<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 10000" ref="myref">
    <g class="draggable-group" v-for="(nodes, index) in myArray" :key="index">
      <rect width="150" height="200" style="fill: rgb(255, 0, 100)" />
      <foreignObject id="x" x="10" width="131" height="100">
        <textarea rows="3" cols="30" v-model="nodes.node_text"></textarea>
      </foreignObject>
    </g>
  </svg>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
// import drag from '@/mixins/drag'

export default {
  name: 'SpaceBase',
  components: {},
  data() {
    return {
      myArray: [],
    }
  },

  computed: {
    ...mapState({
      myNodes: (state) => state.myNodes,
      otherNodes: (state) => state.otherNodes,
      allEmoji: (state) => state.allEmoji,
    }),
  },

  props: {
    added: Boolean,
  },

  watch: {
    added: function () {
      setTimeout(this.loadData, 500)
    },
  },

  methods: {
    loadData() {
      var nodesFiltered = this.myNodes.myNodes.filter(
        (nodes) => nodes.node_deleted == false
      )
      // this should probably be on the tool bar NOT HERE really
      this.$store.dispatch('getMynodes')
      this.$store.dispatch('getEmoji')
      this.$store.dispatch('getPositions')

      this.myArray = nodesFiltered.reverse()
    },
  },

  // mixins: [
  //   drag(this.$refs.svg, function () {
  //     // drag
  //   }),
  // ],

  mounted() {
    makeDraggable(this.$refs.myref)
    setTimeout(this.loadData, 500)
    if (localStorage.nogg_name && localStorage.nogg_microcosm) {
      var devicename = localStorage.nogg_name
      var microcosm = localStorage.nogg_microcosm
      this.$store.dispatch('setMicrocosm', { devicename, microcosm })
      // this.$store.dispatch('getEmoji')
      // this.$store.dispatch('getPositions')
    } else {
      console.log('no')
      // go home
    }
  },
}

// PLAIN JS should be a mixin

function makeDraggable(svg) {
  // var svg = evt.target
  svg.addEventListener('mousedown', startDrag)
  svg.addEventListener('mousemove', drag)
  svg.addEventListener('mouseup', endDrag)
  svg.addEventListener('mouseleave', endDrag)
  svg.addEventListener('touchstart', startDrag)
  svg.addEventListener('touchmove', drag)
  svg.addEventListener('touchend', endDrag)
  svg.addEventListener('touchleave', endDrag)
  svg.addEventListener('touchcancel', endDrag)
  function getMousePosition(evt) {
    var CTM = svg.getScreenCTM()
    if (evt.touches) {
      evt = evt.touches[0]
    }
    return {
      x: (evt.clientX - CTM.e) / CTM.a,
      y: (evt.clientY - CTM.f) / CTM.d,
    }
  }
  var selectedElement, offset, transform
  function initialiseDragging(evt) {
    offset = getMousePosition(evt)
    // Make sure the first transform on the element is a translate transform
    var transforms = selectedElement.transform.baseVal
    if (
      transforms.length === 0 ||
      transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE
    ) {
      // Create an transform that translates by (0, 0)
      var translate = svg.createSVGTransform()
      translate.setTranslate(0, 0)
      selectedElement.transform.baseVal.insertItemBefore(translate, 0)
    }
    // Get initial translation
    transform = transforms.getItem(0)
    offset.x -= transform.matrix.e
    offset.y -= transform.matrix.f
  }
  function startDrag(evt) {
    if (evt.target.classList.contains('draggable')) {
      selectedElement = evt.target
      initialiseDragging(evt)
    } else if (evt.target.parentNode.classList.contains('draggable-group')) {
      selectedElement = evt.target.parentNode
      initialiseDragging(evt)
    }
  }
  function drag(evt) {
    if (selectedElement) {
      evt.preventDefault()
      var coord = getMousePosition(evt)
      transform.setTranslate(coord.x - offset.x, coord.y - offset.y)
    }
  }
  function endDrag() {
    selectedElement = false
  }
}
</script>

<style scoped>
.draggable,
.draggable-group {
  cursor: move;
}

.static {
  cursor: not-allowed;
}
</style>
