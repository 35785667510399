<template>
  <div
    class="nodes"
    v-for="(nodes, index) in otherNodes.otherNodes"
    :key="index"
    :style="{
      backgroundColor: nodes.node_color,
    }"
  >
    <p
      class="readmode"
      :id="nodes.node_id"
      v-html="marked(nodes.node_text)"
    ></p>
    <div class="allemoji">
      <div
        class="eachemoji"
        v-for="(emojis, index) in allEmoji.allEmoji"
        :key="index"
      >
        <p v-if="nodes.node_id == emojis.node_id">
          {{ emojis.emoji_unicode }}
        </p>
      </div>
    </div>
    <button @click="openEmoji(nodes.node_id)">
      <svg
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
        />
      </svg>
    </button>
    <VuemojiPicker
      v-if="showEmoji === nodes.node_id"
      class="emojipicker"
      @emojiClick="($event) => handleEmojiClick(nodes.node_id, $event)"
      :pickerStyle="{}"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import marked from 'marked'
import { VuemojiPicker } from 'vuemoji-picker'

export default {
  name: 'OtherNodes',

  props: {
    // these are for the emoji styling
    // https://github.com/wobsoriano/vuemoji-picker
    // isDark: Boolean,
    pickerStyle: Object,
  },

  data: function () {
    return {
      showEmoji: String,
    }
  },

  components: {
    VuemojiPicker,
  },

  computed: {
    ...mapState({
      otherNodes: (state) => state.otherNodes,
      allEmoji: (state) => state.allEmoji,
    }),
  },

  mounted() {
    this.$store.dispatch('getOthernodes')
    this.$store.dispatch('getEmoji')
    setTimeout(this.loadData, 500)
    // this is to get the last letter typed if no space
    // this also loads in new Emoji
    // May need to EDIT
    setInterval(this.loadData, 5000)
  },

  methods: {
    getNodeid(nodeid) {
      this.nodeid = nodeid
    },
    handleEmojiClick(nodeid, detail) {
      var unicode = detail.emoji.unicode
      var annotation = detail.emoji.annotation
      var skinTone = detail.skinTone
      var emoticon = detail.emoji.emoticon

      this.$store.dispatch('addEmoji', {
        nodeid,
        unicode,
        annotation,
        skinTone,
        emoticon,
      })
      this.$store.dispatch('getEmoji')
    },

    openEmoji(nodeid) {
      if (this.showEmoji == nodeid) {
        this.showEmoji = null
      } else {
        this.showEmoji = nodeid
      }
    },

    loadData() {
      this.$store.dispatch('setOthernodes')
      this.$store.dispatch('getEmoji')
    },
    marked,
  },
}
</script>

<style scoped>
.nodes {
  border: 2px solid black;
}

button {
  background: none;
  border: none;
}

.allemoji {
  font-size: 1.5em;
  padding: 0em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, auto));
}
</style>
