<template>
  <ToolBar @added-node="addedNode" />
  <SpaceBase :added="added" />
</template>

<script>
// @ is an alias to /src
import ToolBar from '@/components/ToolBar.vue'
import SpaceBase from '@/components/SpaceBase.vue'

export default {
  mounted() {},
  name: 'Spatial',

  components: {
    ToolBar,
    SpaceBase,
  },

  data() {
    return {
      added: false,
    }
  },

  methods: {
    addedNode() {
      this.added = !this.added
    },
  },
}
</script>

<style scoped></style>
